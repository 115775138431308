import { useEffect, useRef, useState } from "react";
import { styled } from "styled-components";

export const Timer = ({ question, onEnd, timeLimit = 30000, stopTimer}) => {
    const [milliSeconds, setMilliSeconds] = useState(timeLimit);
    const interval = useRef();

    useEffect(() => {
        interval.current = setInterval(() => {
            setMilliSeconds((prevSeconds) => {
                return prevSeconds > 0 ? prevSeconds - 100 : 0;
            });
        }, 100);

        return () => clearInterval(interval.current);
    }, []);

    useEffect(() => {
        if (milliSeconds <= 0) {
            clearInterval(interval.current);
            onEnd();
        }
    }, [milliSeconds, onEnd]);

    useEffect(() => {
        if (stopTimer) {
            clearInterval(interval.current);
        }
    }, [stopTimer]);

    const progressValue = (timeLimit - milliSeconds) / timeLimit;

    return (
        <TimerContainer>
            <Cron
                progress={progressValue}
                className={`${
                    milliSeconds < 15000
                        ? milliSeconds < 10000
                            ? milliSeconds === 0
                                ? "stop"
                                : "danger"
                            : "warning"
                        : ""
                }`}
            >
                <h1>
                    <span className="seconds">
                        {Math.floor(milliSeconds / 1000)}
                    </span>
                    <span className="decimal">
                        .{(milliSeconds % 1000).toString().slice(0, 2)}
                    </span>
                </h1>
                <Question>{question}</Question>

            </Cron>
        </TimerContainer>
    );
};

const TimerContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    margin-bottom: 80px;
    justify-content: center;
`;

const Question = styled.p`
    padding-left: 12px;
    box-sizing: border-box;
    width: calc(100%);
    text-align: center;
    margin: 0;
`;

const Cron = styled.div`
    max-width: 75%;
    aspect-ratio: 1;
    display: flex;
    position: relative;
    bottom: -20px;
    align-items: center;
    justify-content: center;
    padding: 20px;
    flex-direction: column;

    background: conic-gradient(
        var(--white) calc(${(props) => props.progress} * 100%),
        var(--green) 0
    );

    &::after {
        content: "";
        width: calc(100% - 10px);
        aspect-ratio: 1;
        position: absolute;
        background-color: var(--black);
        border-radius: 400px;
        z-index: 1;
    }
    border-radius: 50%;
    > p {
        font-weight: bold;
        margin: 0;
        z-index: 2;
    }
    > h1 {
        display: flex;
        align-items: baseline; 
        z-index: 2;
        justify-self: flex-start;

        > .seconds {
            width: 60px;
            text-align: end;
            margin-right: 2px; 
        }

        > .decimal {
            width: 30px;
            font-size: 16px;
            opacity: 0.7; 
        }
    }
    &.warning {
        background: conic-gradient(
            var(--white) calc(${(props) => props.progress} * 100%),
            var(--orange) 0
        );
        h1 {
            animation: pulseWarning 1s infinite;
        }
    }
    &.danger {
        background: conic-gradient(
            var(--white) calc(${(props) => props.progress} * 100%),
            var(--red) 0
        );
        h1 {
            animation: pulseDanger 1s infinite;
        }
    }
`;

