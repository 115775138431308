import { solution } from "../../questions";
import styled from "styled-components";
import { FaHeart } from "react-icons/fa";

export const Ending = () => {
	return (
		<Container>
			<h2>
				Era propio <br /> <b>{solution}</b>
			</h2>

			<h4>
				Grazie per aver giocato e <br /> Buon compleanno
			</h4>
			<FaHeart className="heart" size="32px" color="var(--red)" />
		</Container>
	);
};

const Container = styled.div`
	width: 100%;

	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 12dvh;
	> * {
		text-align: center;
	}
    .heart {
        animation: heartBeat 1s infinite ease-in-out;
    }
`;
