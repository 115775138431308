import React, { useMemo, useState, useContext } from 'react';
import { solution, questions } from "../questions"


const defaultValue = {

}

const AnswerContext = React.createContext(defaultValue)

export const AnswerContextProvider = ({ children })=> {

    const solutionLetters =useMemo(()=>[
        ...new Set(
            solution
                .toLowerCase()
                .split("")
                .filter((letter) => letter.length && letter!== " ")
        ),
    ], []);

const solutionWords = solution
		.toLowerCase()
		.split(" ")
		.map((word) => word.split(""));
const [discoveredLetters, setDiscoveredLetters] = useState([]);
const [errorLetters, setErrorLetters] = useState([]);
const [currentLetter, setCurrentLetter] = useState()

    const value = useMemo(()=> ({
        questions,
        solutionLetters,
        solutionWords,
        discoveredLetters,
        setDiscoveredLetters,
        errorLetters,
        currentLetter,
        setCurrentLetter,
        setErrorLetters
    }), [discoveredLetters, errorLetters,currentLetter, solutionLetters, solutionWords])

    return <AnswerContext.Provider value={value}>
        {children}
    </AnswerContext.Provider>
}

export const useAnswerContext = () => useContext(AnswerContext)