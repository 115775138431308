import { useEffect } from "react";
import styled from "styled-components";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

export const GameExplained = () => {
    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);

        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: "#gameExplained",
                start: "top center-=300px",
                end: "bottom-=300 center",
                pin: true,
            },
        });

        tl.to("#scrollingText", { opacity: 1, y: 0 });

        return () => tl.kill();
    }, []);

    return (
        <Container id="gameExplained">
            <ScrollingText id="scrollingText">
                <h3>
                    Ti ricordi <b>Wordle</b>?
                </h3>
                <h5>
                    Beh, ho pensato di farne una versione personalizzata per il
                    tuo compleanno.
                </h5>
                <h5>Alcune semplici regole:</h5>
                <p>
                    Per verificare se una lettera è presente nella soluzione,
                    dovrai <b>rispondere</b> a una domanda <b>entro 30 secondi.</b>
                </p>
                <p>
                    Se <b>sbaglierai</b> la risposta o il timer <b>scadrà</b>,
                    ti basterà semplicemente <b>riprovare</b>.
                </p>
                <p>
                    Se invece la risposta sarà corretta, potrai scoprire se la
                    lettera è o meno presente nella soluzione.
                </p>
                <p>
                    Anche se hai capito la soluzione, sei <b>gentilmente </b>
                    pregata di completare le lettere mancanti.
                </p>
            </ScrollingText>
        </Container>
    );
};

const Container = styled.div`
    height: 120vh;
    margin-bottom: 20vh;
    > * {
        text-align: center;
    }
`;

const ScrollingText = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4vh;
    opacity: 0;
    > p {
        text-align: justify;
    }
`;
