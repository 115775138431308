import styled from "styled-components";
import { Solution } from "../components/Solution";
import { MainContainer } from "../components/mainContainer";
import { AllLetters } from "../components/AllLetters";
import { useAnswerContext } from "../contexts/answer";

export const Play = () => {
	const { discoveredLetters, errorLetters } = useAnswerContext()

	return (
		<MainContainer className="center-vertically">
			<MainTitle>
				Dove si trova il tuo regalo?
			</MainTitle>
			<Solution/>
			<AllLetters
				errorLetters={errorLetters}
				discoveredLetters={discoveredLetters}
				onLetterSelected={(letter) => {
					
				}}
			/>
		</MainContainer>
	);
};

const MainTitle = styled.h3`
	text-align: center;
`;
