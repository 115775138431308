import styled from "styled-components"
import { FaAngleDoubleDown } from "react-icons/fa";

export const HomeTitle = ()=>{

    return <Container>
        <h1>Ciao <b>Chiara</b></h1>
        <h3>
            Benvenuta in questo piccolo <b>giochino</b> <br/> fatto <b>appositamente</b> per non darti subito il<b> regalo </b>
        </h3>
        <ScrollContainer>
            <h4>
                Continua a scrollare per scoprire di cosa si tratta
            </h4>
            <FaAngleDoubleDown className="scrollDown" size="28px" color="var(--red)"/>
        </ScrollContainer>
    </Container>
}

const Container = styled.div`
    width: 100%;
    
    display:flex;
    flex-direction:column;
    margin-bottom: 60dvh;
    gap: 20dvh;
    > * {
        text-align: center;
    }
`

const ScrollContainer = styled.div`
display: flex;
 flex-direction: column;
 gap: 2dvh;
 align-items: center;
    .scrollDown{
        position: relative;
        animation: scrollDown 1s infinite;
    }
`