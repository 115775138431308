import './App.css';
import React from 'react';
import { AppRouter } from './routes';
import './App.css'
import { AnswerContextProvider } from './contexts/answer';

const  App= () =>{
  
  return (
      <AnswerContextProvider>

          <AppRouter/>
      </AnswerContextProvider>
  );
}

export default App;
