import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { gsap } from "gsap";

export const Meme = () => {
  const containerRef = useRef(null);

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: containerRef.current,
        start: "top center",
        end: "bottom-=350px center",
        pin: true,
      },
    });

    tl.to("#meme", { opacity: 1, y: 0 });

    return () => tl.kill();
  }, []);

  return (
    <Container ref={containerRef} id="mainContainer">
      <TextContainer id="meme">
        <h4> Avrei potuto farti un <b>bigliettino</b> </h4>
        <h4>
          Ma io faccio <b>siti</b>  mica sono un <b>amanuense</b>
        </h4>
      </TextContainer>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  
  box-sizing:border-box;
  height: 100vh;
  display: flex;
  margin-bottom: 20dvh;

  > * {
    font-size: 20px;
    text-align: center;
  }
`;

const TextContainer = styled.div`
  height: 20vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s ease, transform 0.5s ease;
`;
