// import styled from "styled-components";
import { MainContainer } from "../components/mainContainer";
import { Ending } from "../components/thankyou/Ending";

export const Thankyou = ()=> {
    return (
        <MainContainer>
        <Ending/>
         </MainContainer>
    )
}