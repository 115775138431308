import styled from "styled-components";

export const MainContainer = ({ children, className }) => {
	return <Container className={`main_container ${className}`}>{children}</Container>;
};

const Container = styled.div`
	width: 100%;
    min-height: 100dvh;
    max-width: 412px;
    background-color: var(--black);
    box-sizing: border-box;
    padding: 30px;
    margin-left: auto;
    margin-right:auto;
    
    display:flex;
    flex-direction: column;
    &.center-vertically{
        justify-content: space-between;
    }
`;
