export const questions = {
    a: {
        q: "Di te una cosa abbiamo capito, non ti va proprio a genio __. Completare con:",
        a: [
            { right: false, text: "Il patriarcato" },
            { right: false, text: "Maksim" },
            { right: true, text: "OnlyFans" },
            { right: false, text: "Il Pollo" }
        ],
        by: "Michele"
    },
    b: {
        q: "Si usa per indicare un grosso pezzo di legno da ardere",
        a: [
            { right: false, text: "Lanterna" },
            { right: false, text: "Candela" },
            { right: true, text: "Ciocco" },
            { right: false, text: "Piadina" }
        ],
        by: "Maksim"
    },
    c: {
        q: "Qual è la capitale dell'Italia?",
        a: [
            { right: false, text: "Parigi" },
            { right: false, text: "Berlino" },
            { right: true, text: "Roma" },
            { right: false, text: "Madrid" }
        ]
    },
    d: {
        q: "Quale elemento chimico ha simbolo 'O'?",
        a: [
            { right: false, text: "Oro" },
            { right: false, text: "Argento" },
            { right: true, text: "Ossigeno" },
            { right: false, text: "Azoto" }
        ]
    },
    e: {
        q: "Cosa abbiamo scoperto essere estremamente nocivo per Simone a Jesolo?",
        a: [
            { right: false, text: "L'acqua di mare" },
            { right: false, text: "L'amicizia©" },
            { right: true, text: "Il Burro" },
            { right: false, text: "Il sole" }
        ],
        by: "Simone"
    },
    f: {
        q: "Chi ha dipinto la 'Mona Lisa'?",
        a: [
            { right: false, text: "Vincent van Gogh" },
            { right: false, text: "Pablo Picasso" },
            { right: true, text: "Leonardo da Vinci" },
            { right: false, text: "Claude Monet" }
        ]
    },
    g: {
        q: "Cos'è la radiazione cosmica di fondo?",
        a: [
            { right: false, text: "Le radiazioni dei buchi neri" },
            { right: false, text: "La tossicità di una puzzetta di maks" },
            { right: true, text: "I \"resti\" del big bang" },
            { right: false, text: "Le radiazioni stellari" }
        ],
        by: "Alì"
    },
    h: {
        q: "Qual è la lingua più parlata nel mondo?",
        a: [
            { right: false, text: "Inglese" },
            { right: false, text: "Spagnolo" },
            { right: true, text: "Cinese" },
            { right: false, text: "Hindi" }
        ]
    },
    i: {
        q: "Da quale figura viene uccisa la meretrice in Lupus in Tabula se decide di dormirci insieme?",
        a: [
            { right: false, text: "Il contadino" },
            { right: false, text: "Il veggete" },
            { right: true, text: "Il lupo" },
            { right: false, text: "Se stessa" }
        ]
    },
    j: {
        q: "Se un rettangolo ha lunghezza 8 cm e larghezza 5 cm, qual è la sua area?",
        a: [
            { right: false, text: "30 cm²" },
            { right: false, text: "35 cm²" },
            { right: true, text: "40 cm²" },
            { right: false, text: "45 cm²" }
        ]
    },
    k: {
        q: "Quanto è il risultato di 3/4 + 1/2?",
        a: [
            { right: false, text: "1" },
            { right: false, text: "1 + 1/3" },
            { right: true, text: "1 + 1/4" },
            { right: false, text: "1 + 1/2" }
        ]
    },
    l: {
        q: "Cosa vuol dire \"EDIBILE\"?",
        a: [
            { right: false, text: "Ci si può costruire sopra" },
            { right: false, text: "Edificio con poce pile" },
            { right: true, text: "Commestibile" },
            { right: false, text: "Maksim è dislessico" }
        ],
        by: "Anna"
    },
    m: {
        q: "Quale pianeta è conosciuto come la 'Stella del Mattino'?",
        a: [
            { right: false, text: "Marte" },
            { right: false, text: "Giove" },
            { right: true, text: "Venere" },
            { right: false, text: "Saturno" }
        ]
    },
    n: {
        q: "Quale sarebbe il lavoro che reputi adatto a Maksim?",
        a: [
            { right: false, text: "Modello per Pantene" },
            { right: false, text: "Ballerina di danza classica" },
            { right: true, text: "Sostituzione come Rapunzel ai compleanni delle bambine" },
            { right: false, text: "Stripper in un bordello di basso borgo" }
        ],
        by: "Anna"
    },
    o: {
        q: "In 7th Sea, hai ripagato un molestatore con la sua stessa moneta. Cosa hai fatto?",
        a: [
            { right: false, text: "Gli hai spiegato come scaricare RAID SHADOW LEGEND con il codice promozionale maksima90xxx" },
            { right: false, text: "Lo hai picchiato a sangue freddo gridando \"Girl poweeeer\"" },
            { right: true, text: "Gli hai toccato il culo e dato un bacio sul naso" },
            { right: false, text: "Hai chiuso gli occhi e detto \"Ti fidi di me?\"" }
        ],
        by: "Michele"
    },
    p: {
        q: "Chi ha scritto i Promessi Sposi?",
        a: [
            { right: false, text: "Alessandro Magno" },
            { right: false, text: "Alessandro Garau" },
            { right: true, text: "Alessandro Manzoni" },
            { right: false, text: "Alessandro Borghese" }
        ],
        by: "Maksim"
    },
    q: {
        q: "Quale animale è conosciuto come 'il re della giungla'?",
        a: [
            { right: false, text: "Elefante" },
            { right: false, text: "Tigre" },
            { right: true, text: "Leone" },
            { right: false, text: "Giraffa" }
        ]
    },
    r: {
        q: "Quale di queste parole è un pronome personale soggetto?",
        a: [
            { right: false, text: "Libro" },
            { right: false, text: "Cielo" },
            { right: true, text: "Loro" },
            { right: false, text: "Montagna" }
        ]
    },
    s: {
        q: "Chi ha scritto la Divina Commedia?",
        a: [
            { right: false, text: "Alessandro Manzoni" },
            { right: false, text: "Giovanni Boccaccio" },
            { right: true, text: "Dante Alighieri" },
            { right: false, text: "Gabriele D'Annunzio" }
        ]
    },
    t: {
        q: "Cosa si intende con integrità referenziale?",
        a: [
            { right: false, text: "Il fatto che una referenza lavorativa debba essere affidabile e sincera" },
            { right: false, text: "Qualcosa che ha a che fare con la validazione di un software" },
            { right: true, text: "Qualcosa riguardante delle chiavi dei database basati su SQL" },
            { right: false, text: "Riguarda la coerenza di una frase" }
        ],
        by: "Alì"
    },
    u: {
        q: "Qual è il professore di algebra più gentile al mondo?",
        a: [
            { right: false, text: "Saw" },
            { right: false, text: "Hitler" },
            { right: true, text: "Chiunque, basta che non sia Giuzzi" },
            { right: false, text: "Giuzzi" }
        ],
        by: "Alessandro"
    },
    v: {
        q: "In quale continente si trova l'Egitto?",
        a: [
            { right: false, text: "Asia" },
            { right: false, text: "Europa" },
            { right: true, text: "Africa" },
            { right: false, text: "America" }
        ]
    },
    w: {
        q: "Qual è la più grande isola del mondo?",
        a: [
            { right: false, text: "Borneo" },
            { right: false, text: "Groenlandia" },
            { right: true, text: "Isola di Honshu" },
            { right: false, text: "Isola di Sicilia" }
        ]
    },
    x: {
        q: "Quale fiume scorre attraverso Il Cairo?",
        a: [
            { right: false, text: "Senna" },
            { right: false, text: "Tamigi" },
            { right: true, text: "Nilo" },
            { right: false, text: "Danubio" }
        ]
    },
    y: {
        q: "Quale pianeta è conosciuto come 'la Stella Rossa'?",
        a: [
            { right: false, text: "Marte" },
            { right: false, text: "Giove" },
            { right: true, text: "Antares" },
            { right: false, text: "Sirio" }
        ]
    },
    z: {
        q: "Qual è la moneta ufficiale del Giappone?",
        a: [
            { right: false, text: "Yuan" },
            { right: false, text: "Won" },
            { right: true, text: "Yen" },
            { right: false, text: "Ringgit" }
        ]
    }
};

export const solution = "Nel tuo Bagagliaio";
