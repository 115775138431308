import React, { useEffect } from "react";
import styled from "styled-components";
import { gsap } from "gsap";

export const Thanks = () => {
  const names = [
    { name: "Alessandro", last_name: "Garau" },
{ name: "Alì", last_name: "Maqsood Haider" },
{ name: "Simone", last_name: "Lazzari" },
{ name: "Michele", last_name: "Quaresmini" },
{ name: "Anna", last_name: "Vurchio" },
{ name: "Roberto", last_name: 'Zubani' },
  ];

  useEffect(() => {
    const tl = gsap.timeline({
        scrollTrigger: {
            trigger: ".name",
        }
    })
    tl.fromTo(
        ".name",
        {
          opacity: 0,
          x: -50,
        },
        {
          opacity: 1,
          x: 0,
          stagger: 0.2,
          duration: 1,
          ease: "power3.out",
        }
      );
  }, []);

  return (
    <Container>
      <h4>Grazie all'aiuto di :</h4>
      {names.map((name, i) =>
        (
          <h5 key={i} className="name">
            {name.last_name} <b>{name.name}</b>
          </h5>
        )
      )}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  margin-bottom: 30dvh;
  > * {
    text-align: center;
  }
  .name {
    opacity: 0
  }
`;
