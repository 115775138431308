// import styled from "styled-components";
import styled from "styled-components";
import { Meme } from "../components/home/Meme";
import { MainContainer } from "../components/mainContainer";
import { HomeTitle } from "../components/home/Title";
import { Thanks } from "../components/home/Thanks";
import { GameExplained } from "../components/home/GameExplained";
import { useNavigate } from "react-router-dom";

export const Intro = ()=> {

    const navigateTo = useNavigate()
    return (
        <MainContainer>
            <HomeTitle/>
            <Meme/>
            <GameExplained />
            <Thanks />
            <NextButton onClick={()=>navigateTo('/play')}>
                <span>INIZIAMO</span>
            </NextButton>
         </MainContainer>
    )
}

const NextButton = styled.div`
    width: 200px;
    height: 60px;
    background-color: var(--red);
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    > span {
        font-weight: bold;
        font-size: 1.5rem;
    }
`