import styled from 'styled-components'

export const Modal = ({show, text, time, success  }) => {

  

 

    return (
        <ModalContainer className={ `${show ? 'show' : ''} ${success? 'success' : ''}` }>
            <ModalBox className={'box'}  time={time}>
                <p>{text}</p>
            </ModalBox>
        </ModalContainer>
    );
};

const ModalContainer = styled.div`
    width: 100vw;
    height: 100dvh;
    position: fixed;
    top:0;
    left:0;
    display: flex;
    position: fixed;
    justify-content:center ;
    align-items:center ;
    z-index: -1;
    opacity:0;
    background-color: #0000;
    transition: background-color .2s ease-in;
    &.show{
        z-index: 100;
        opacity:1;
        background-color: #0005;
        .box{
            bottom: 30px;
            &::after{
               max-width:0;
            }
        }
    }
    &.success{
        .box{
            &::after{
                background-color: var(--green)
            }
        }
    }
`;

const ModalBox = styled.div`
    width: 380px;
    min-height: 100px;
    position:absolute;
    background-color: var(--white);
    display:flex; 
    align-items: center ;
    left: calc(calc(100vw - 380px) / 2);
    bottom: -500px;
    transition: bottom .5s ease-in;
    border-radius: 6px;
    p {
        width:100%;
        text-align:center ;
        color: var(--black);
    }
    /* &::before{
        height: 6px;
        width: 100%;
        content: '';
        position: absolute;
        z-index:6;
        bottom:6px;
        background-color: var(--white) ;
    } */
    &::after{
        content: '';
        z-index: 5;
        width:100%;
        max-width: 100%;
        background-color: var(--red) ;
        position: absolute;
        bottom: 0;
        height: 6px;
        border-radius: 0 12px 6px 6px;
        
        transition: max-width ${(props)=> props.time} ease-in;
    }
    
`;
