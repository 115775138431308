import styled from "styled-components";
import { useAnswerContext } from "../contexts/answer";
import { Letter } from "./Letter";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export const Solution = () => {
	const { discoveredLetters, solutionWords, solutionLetters } = useAnswerContext()
    const [finished,setFinished] = useState();
    const navigateTo = useNavigate()
    const checkFinished = useCallback(()=> {
        setFinished( solutionLetters.every(letter=> { console.log( 'letter: ',letter, discoveredLetters.includes(letter)); return discoveredLetters.includes(letter)}))
    },[discoveredLetters, solutionLetters])

    useEffect(()=>{
        console.log('finished:', finished)
        if(finished){
            setTimeout(()=>{
                navigateTo('/thankyou')
            }, 3000)
        }
    },[finished, navigateTo])

    useEffect(()=>{
        console.log('hey')
        checkFinished()
    },[checkFinished])
	return (
		<Container >
			{solutionWords.map((word, i) => {
				return (
					<div className="word" key={i}>
						{word.map((letter, i) => {
							return <Letter letter={letter} key={i} status={`${discoveredLetters.includes(letter) ? "success": ''} solution ${finished? 'completed' : ''}` }  />;
						})}
					</div>
				);
			})}
		</Container>
	);
};

const Container = styled.div`
    display:flex;
    flex-direction: column;
    .word{
        display: flex;
        margin-bottom: 12px;
        display:flex;
        justify-content: center;
        .letter{
            margin-right:8px;
            
            &:last-child{
                margin-right:0;
            }

        }
    }
`;
