// import styled from "styled-components";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { MainContainer } from "../components/mainContainer";
import { useAnswerContext } from "../contexts/answer";
import { Timer } from "../components/question/Timer";
import { Letter } from "../components/Letter"
import { Answer } from "../components/question/Answer";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import { Modal } from "../components/Modal";
import { IoArrowBack } from "react-icons/io5";
import styled from "styled-components";

export const Question = () => {
    const { letter } = useParams();
    const [question, setQuestion] = useState();
    const [showModal, setShowModal] = useState(false)
    const [modalSuccess, setModalSuccess] = useState(false);
    const [modalText, setModalText ] = useState("");
    const [stopTimer, setStopTimer]= useState(false)
    const {
        questions,
        setCurrentLetter,
        setErrorLetters,
        errorLetters,
        discoveredLetters,
        setDiscoveredLetters,
        solutionLetters,
    } = useAnswerContext();

    const addToErrors = () => {
        const temp = errorLetters;
        setErrorLetters([...temp, letter]);
    };
    const addToCorrect = () => {
        const temp = discoveredLetters;
        setDiscoveredLetters([...temp, letter]);
    };

    const checkLetter= ()=>{
        if(solutionLetters.includes(letter)){
            addToCorrect()
        }else{
            addToErrors();
        }
      
    }


    const navigate = useNavigate();

	const returnToMain = () => {
        setTimeout(()=> {
            navigate(`/play`);
        }, 3500)
	};

    useEffect(() => {
        setCurrentLetter(letter);
        if (questions) {
            setQuestion({
                q: questions[letter].q,
                a: _.sampleSize(
                    questions[letter].a,
                    questions[letter].a.length
                ),
            });
        }
    }, [letter, questions, setCurrentLetter]);
    return (
        <MainContainer>
            {question ? (
                <>
                    <Timer
                        question={question.q}
                        stopTimer={stopTimer}
                        onEnd={() => {
                            setModalSuccess(false)
                            setModalText('Tempo scaduto, dovrai riprovare')
                            setShowModal(true)
                            returnToMain()
                            
                        }}
                    />
                    {question.a.map((answer, i) => (
                        <Answer
                            key={i}
                            text={answer.text}
                            onClick={() => {
                                setStopTimer(true);
                                if (answer.right) {
                                    setModalSuccess(true)
                                    setModalText('Complimenti, Risposta esatta')
                                    checkLetter()
                                }else{
                                    setModalSuccess(false)
                                    setModalText('Hai fatto un oopsie, dovrai riprovare')
                                }
                                setShowModal(true)
                                returnToMain()

                            }}
                        />
                    ))}
                </>
            ) : (
                <></>
            )}
            <Footer>
                <Letter letter={letter} />
            <BackContainer onClick={()=> {navigate('/play')}}>
                <IoArrowBack color="var(--white)" size={18}/> 
                <p>Back</p>
            </BackContainer>
            </Footer>
            <Modal show={showModal} text={modalText} time={"3s"} success={modalSuccess} />
        </MainContainer>
    );
};


const BackContainer = styled.div`
    display: flex;
    align-items: center;
    width: fit-content;
    
    margin-left: auto;
    
    

`

const Footer = styled.div`
    width: 100%;
    padding-bottom: 40px;
    display: flex;
    margin-top: 40px;
    justify-contnet:space-between;
`