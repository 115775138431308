import styled from "styled-components";

export const Answer = ({ text, className, onClick }) => {
    return (
        <AnswerBox className={className} onClick={onClick}>
            <p>{text}</p>
        </AnswerBox>
    );
};

const AnswerBox = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid var(--white);
    border-radius: 12px;
    margin-bottom: 28px;
    box-sizing: border-box;
    padding: 8px 12px; 
    
`;
