import { useEffect, useState } from "react";
import styled from "styled-components";
import { useAnswerContext } from "../contexts/answer";
export const Letter = ({ letter, status, onClick }) => {
	const [mounted, setMounted] = useState(false);

    const {currentLetter} = useAnswerContext()

	useEffect(() => {
		setTimeout(()=>{setMounted(true)}, 50);
	}, []);

	return (
		<Container
			onClick={
				onClick && !["error", "completed"].includes(status)
					? () => onClick()
					: () => {}
			}
			className={`${status} ${mounted ? "mounted" : ""} ${currentLetter === letter ? 'current' : ''}  letter`}
		>
			<p>{letter}</p>
		</Container>
	);
};

const Container = styled.div`
	width: 30px;
	aspect-ratio: 1;
	background-color: var(--white);
	border-radius: 4px;
	box-sizing: border-box;
	display: flex;
	justify-content: center;
	align-items: center;
	
	> p {
		margin: 0;
		font-weight: 800;
		text-transform: uppercase;
		color: var(--black);
	}
	&.solution {
		> p {
			opacity: 0;
		}
	}

	&.success.completed{

                
		animation: gameFinished${()=> Math.random()>= 0.5 ? 'Left' : 'Right'} 1s infinite;

	}

	&.mounted {
        &.current {
        transition: background-color 1s ease-out;
            > p {
                transition: opacity 1s ease-out;
            }
        }
		&.success {
			> p {
				opacity: 1;
				color: var(--black);
			}
		}
		&.completed {

			
            background-color: var(--black);
            
			> p {
				color: var(--green);
			}
		}

		&.error {
			/* background-color: var(--red);         */
			background-color: var(--black);
			> p {
				color: var(--red);
			}
		}
	}
`;
