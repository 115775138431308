import styled from "styled-components";
import { Letter } from "./Letter";
import { questions } from "../questions";
import { useNavigate } from "react-router-dom";
import { useAnswerContext } from "../contexts/answer";

export const AllLetters = () => {
	const letters = Object.keys(questions);
	const { discoveredLetters, errorLetters } = useAnswerContext()

	const navigate = useNavigate();

	const navigateTo = (letter) => {
		navigate(`/questions/${letter}`);
	};

	return (
		<Container>
			{letters.map((letter, i) => {
				return (
					<Letter
						letter={letter}
						key={i}
						status={
							discoveredLetters.includes(letter) 
                                ? 'completed' 
                                : errorLetters.includes(letter) 
                                    ? 'error'
                                    : 'ok' 
						}
						size="30"
                        onClick={()=>navigateTo(letter)}
					/>
				);
			})}
		</Container>
	);
};

const Container = styled.div`
	display: flex;
	flex-flow: wrap ;
    justify-content: center;

		.letter {
			margin-right: 8px;
            margin-bottom:12px;
			&:last-child {
				margin-right: 0;
			}
		}
`;
