import {
	BrowserRouter as Router,
	Navigate as Redirect,
	Route,
	Routes as Switch,
} from "react-router-dom";
import { Intro } from "../pages/intro";
import { Play } from "../pages/main";
import { Question } from "../pages/question";
import { Thankyou } from "../pages/thankyou";

export const AppRouter = () => {
	return (
		<Router>
			<Switch>
				{/* Public Routes */}
				<Route path="/" Component={() => <Intro />} />
				<Route path="/play" Component={() => <Play />} />
				<Route path="/questions/:letter" Component={() => <Question />} />
				<Route path="/thankyou" Component={() => <Thankyou />} />
				<Route render={() => <Redirect to="/" />} />
			</Switch>
		</Router>
	);
};
